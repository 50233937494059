<template>
    <ODataTable :dataObject="local_dsESignatures" hideGridMenu hideActionColumn ref="ref_ODataTable2" :headerTitle="$t('E-Signatures')">
        <template #cardheader>
            <button type="button" class="btn btn-sm btn-outline-primary me-2" @click="syncWithSource" :title="$t('Updated E-Signatures from solution provider.')">
            <span class="ms-1">{{$t('Update Selected Session')}}</span>
            </button>
        </template>
        <OColumn field="ID" width="80" sortable hide/>
        <OColumn field="Process" width="150" :headerName="$t('Solution')" :hide="props.url == '/nt/scope-workflow'" sortable/>
        <OColumn field="EnvelopeId" width="300" :headerName="$t('Session ID')"   :hide="props.url == '/nt/scope-workflow'"  sortable/>
        <OColumn field="SentDate" width="130" :headerName="$t('Sent')"  format="General Date Short Time" :hide="props.url == '/nt/scope-workflow'" sortable/>
        <OColumn field="Status" width="110" sortable>
            <template #default="{row}"> 
                <i :class="{'bi bi-trash text-secondary': row.Status?.toLowerCase() === 'deleted',
                    'bi bi-check-circle text-success': row.Status?.toLowerCase() === 'completed',
                    'bi bi-envelope text-warning': row.Status?.toLowerCase() === 'sent'}">
                </i>
                {{ row.Status }}
            </template>
        </OColumn>
        <OColumn field="Completed" width="130" :headerName="$t('Completed')" :hide="props.url == '/nt/scope-workflow'" format="General Date Short Time" sortable/>
        <OColumn colId="Recipients" field="AR1.Recipients" :headerName="$t('Recipients')" width="350"  v-slot="{row}"
                filter="OFilter" :filterParams="{
                        autocomplete: {
                            existingValuesOnly: true,
                            awaitForAutocomplete: true,
                            disableAutoSelect: true
                        }
                    }" >
            <ul>
                <li v-for="(extSig, index) in row.AR1?.Recipients" :key="index" >
                        {{ extSig.Name }} ({{ extSig.SignedDate ? $formatDate(extSig.SignedDate , 'General Date Short Time') : "Pending" }})

                    <button v-if="!extSig.SignedDate && extSig.PendingMySignature" class="btn btn-outline-primary btn-sm float-end" 
                        :title="$t('Your signature is required. Please sign.')" @click="signDocument(extSig.ExternalSignature_ID)">
                        <i class="bi bi-pencil"></i>
                    </button>
                </li>
            </ul>
        </OColumn>
        <OColumn colId="Files" field="AR2.Files" :headerName="$t('Files')" width="350" v-slot="{row}"
                filter="OFilter" :filterParams="{
                        autocomplete: {
                            existingValuesOnly: true,
                            awaitForAutocomplete: true,
                            disableAutoSelect: true
                        }
                    }" >
            <ul class="list-unstyled">
                <li v-for="(extSig, index) in row.AR2?.Files" :key="index" >
                    <a :href="`/nt/api/view-pdf/sviw_System_ExternalSignatureAttachments/${extSig.PrimKey}`" target="_blank" class="text-decoration-none">
                        {{ extSig.FileName }}
                    </a>
                </li>
            </ul>
        </OColumn>
        <OColumn field="Updated" width="130" sortable format="General Date Short Time"  :hide="props.url == '/nt/scope-workflow'" />
        <OColumn colId="customAction" :width="28" headerName="" suppressMovable>
            <template #default="{row}">
                <OActionDelete :dataObject="local_dsESignatures" v-if="!row.Deleted" title="Delete record" 
                    style="cursor: pointer;left: 1px;position: relative;" :row="row" class="btn btn-sm btn-link p-0 text-center">
                    <i class="bi bi-x-lg"></i>
                </OActionDelete>
            </template>
        </OColumn>
    </ODataTable>
</template>
<script setup>
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { OActionDelete } from "o365-data-components";
    import { onMounted, ref } from 'vue';
    import { ODataTable } from 'o365-datagrid'
    import { useDataObjectEventListener } from 'o365-vue-utils';
    import { alert } from 'o365-vue-services';
    import { confirm as confirmDialog } from 'o365-vue-services'

    const props = defineProps({
        dataObject: Object,
        source: {type: String},
        source_Id: {type: Number},
        url: {type: String}
    })

    const local_dsESignatures =  getOrCreateDataObject({
        id: 'local_dsESignatures',
        viewName: 'sviw_System_ExternalSignatures',
        appId: props.dataObject.appId,
        maxRecords: 50,
        whereClause: "EnvelopeId IS NOT NULL",
        loadRecents: false,
        distinctRows: false,
        disableAutoLoad: false,
        allowDelete: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: props.source,
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number" },
                {name: "EnvelopeId"},
                {name: "Name"},
                {name: "Status"},
                {name: "Created", type: "datetime"},
                {name: "SentDate", type: "datetime"},
                {name: "Completed", type: "datetime"},
                {name: "EnvelopeId"},
                {name: "Process"},
                {name: "Process_ID", type: "number" },
                {name: "Deleted", type: "datetime"},
                {name: "OrgUnit_ID", type: "number" },
                {name: "SharedFolder_ID", type: "number" },
                { name: "CreatedBy_ID", type: "number" },
                { name: "Updated", type: "datetime" },
                { name: "UpdatedBy_ID", type: "number" },
                { name: "ScopeItem_ID", type: "number" },
                { name: "ProcurementStage_ID", type: "number" },
                { name: "Document_ID", type: "number" },
                
            ],
        subSelectDefinitions: [
            {
            "fields": [
                {
                    "name": "Recipients",
                    "subFields": [
                       "Name",
                        "SignedDate",
                        "PendingMySignature",
                        "ExternalSignature_ID"
                    ]
                }
            ],
            "bindings": [
                {
                    "masterField": "ID",
                    "operator": "equals",
                    "detailField": "ExternalSignature_ID"
                }
            ],
            "alias": "AR1",
            "viewName": "sviw_System_ExternalSignaturesRecipients"
        },
        {
            "fields": [
                {
                    "name": "Files",
                    "subFields": [
                        "PrimKey",
                        "FileName",
                        "FileSize",
                        "FileUpdated"
                    ]
                }
            ],
            "bindings": [
                {
                    "masterField": "ID",
                    "operator": "equals",
                    "detailField": "ExternalSignature_ID"
                }
            ],
            "alias": "AR2",
            "viewName": "sviw_System_ExternalSignatureAttachments"
        }]
    })
    
    useDataObjectEventListener(local_dsESignatures,'BeforeDelete', (pOptions, pRow) => { 
        pOptions.cancelEvent = true;
        confirmDialog({
            message: $t(`Deleting this will also mark the envelope as deleted in ${pRow.Process}. Do you wish to proceed?`),
            title: $t(`Delete E-Signature session`),
        }).then(async () => {
            deleteDocument(pRow.ID);
        }).catch(() => {});
        
    });

    const deleteDocument = (pID) => {
        var vDelete = alert( "Deleting...", 'Info', { autohide : false } );
        fetch(`/nt/api/externalsignature/esign22/Delete`,
        {method: 'POST', 
            body:JSON.stringify({ExternalSignature_ID: pID}), 
            headers: {"Content-Type": "application/json"}}
        ).then(async (response) => {
            vDelete?.close();
            if(response.status != 200){
                response.text().then(errorBody => {
                    console.log(errorBody)
                    alert(errorBody.split(' at ')[0] ?? errorBody,'danger');
                });
            } else { 
                //local_dsESignatures.load();
                props.dataObject.load();
            }
        });
    }

    const signDocument = (pID) => {
        var vReturnUrl = window.location.protocol + "//" + window.location.hostname  + props.url + '?ID=' + props.source_Id;
        fetch(`/nt/api/externalsignature/esign22/Sign`, {
            method:"POST",
            headers:{
            'Content-Type': 'application/json'
            },
            body:JSON.stringify({ExternalSignature_ID: pID, ReturnUrl:vReturnUrl})
        }).then(async (response) => {
            if(response.status != 200){
                response.text().then(errorBody => {
                    console.log(errorBody)
                    alert(errorBody.split(' at ')[0] ?? errorBody,'danger');
                });
            } else { 
                let url =  await response.json();;
                window.open(url, "_parent"); 
            }
        });
    }

    const syncWithSource = async () => {
        const preparedRows = []; 
        
        if (local_dsESignatures?.selectionControl?.selectedRows?.length === 1) {
            const ids = await local_dsESignatures.selectionControl.getSelectedRows();
            ids.forEach(sel => {
                if (sel.EnvelopeId !== null && sel.Deleted === null && sel.Completed === null) {
                    preparedRows.push({ ExternalSignature_ID: sel.ID, EnvelopeId: sel.EnvelopeId });
                    var vUpdate = alert( "Updating...", 'info', { autohide : false } );
                    fetch(`/nt/api/externalsignature/esign22/UpdateStatus`,
                        {method: 'POST', 
                            body:JSON.stringify(preparedRows[0]),
                            headers: {"Content-Type": "application/json"}}
                        ).then(async (response) => {
                            vUpdate?.close();
                            if(response.status != 200){
                                response.text().then(errorBody => {
                                    console.log(errorBody)
                                    alert(errorBody.split(' at ')[0] ?? errorBody,'danger');
                                });
                            } else { 
                                //local_dsESignatures.load();
                                props.dataObject.load();
                            }
                        });
                    return true;
                } else {
                    alert("Please select only one session that is not deleted / completed and try again.", 'warning', { autohide: true, delay: 3000 });
                    return false;
                }
            });
        }else {
            alert("Please select only one session that is not deleted / completed and try again.", 'warning', { autohide: true, delay: 3000 });
            return false;
        }
    }
    local_dsESignatures.load();
</script>